import axios from 'axios'
import { ElNotification } from 'element-plus'
import router from '../router';

var instance = axios.create();

instance.defaults.baseURL = window.vars.baseURL;
instance.defaults.withCredentials = false;
instance.defaults.timeout = 8000;
instance.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
instance.defaults.headers.post['Content-Type'] = 'application/json';


// 全局 get 请求添加时间戳
instance.interceptors.request.use(config => {
    // 如果是 get 请求但是没有带参数，给参数一个缺省值，以便加上时间戳避免缓存
    if (!config.params) {
        config.params = {};
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    config.cancelToken = source.token;
    const now = new Date().getTime();
    if (config.method === 'get') {
        config.params.timestamp = now;
    } else if (config.method === 'post') {
        config.headers.common['Request-Timestamp'] = now;
        if (window.myInfo && window.myInfo.status === 2) {
            ElNotification.error({
                message: '当前用户无权操作',
                duration: 5000,
                dangerouslyUseHTMLString: true
            });
            source.cancel();
        } 
    }
    if (localStorage.getItem('UserLoginToken')) {
        config.headers.common.Authorization = localStorage.getItem('UserLoginToken');
    }
    return config;
}, error => Promise.reject(error));


// 全局response统一处理
instance.interceptors.response.use(
    response => {
        if (response.data && response.data.code && (response.data.code !== 100)) {
            ElNotification.error({
                message: response.data.message ? `发生错误：<p>地址：${response.config.url}</p><p>内容：${response.data.message}</p>` : '未知异常，请确认输入是否异常，仍无法解决请与技术支持联系。',
                duration: 5000,
                dangerouslyUseHTMLString: true
            });

            if (response.data.code === 99000) {
                router.replace({                        
                    name: 'login',                        
                    query: { 
                        redirect: router.currentRoute.fullPath 
                    }
                });
            }
        }
        return response.data;
    },
    error => {
        // 如果该请求返回的 http code 不为200，则会进入这个异常捕获
        // 如果返回了 message，只提示 message
        // 否则提示 http 的错误信息
        if (error && error.response && error.response.status) {            
            switch (error.response.status) {                
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    ElNotification.error({
                        message: `发生错误：<p>地址：${error.response.config.url}</p><p>内容：请登录</p>`,
                        duration: 5000,
                        dangerouslyUseHTMLString: true
                    });
                    router.replace({                        
                        name: 'login',                        
                        query: { 
                            redirect: router.currentRoute.fullPath 
                        }
                    });
                    break;

                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面                
                case 403:
                    ElNotification.error({
                        message: `发生错误：<p>地址：${error.response.config.url}</p><p>内容：没有权限</p>`,
                        duration: 5000,
                        dangerouslyUseHTMLString: true
                    });                 
                    break; 
                // 404请求不存在
                case 404:
                    ElNotification.error({
                        message: `发生错误：<p>地址：${error.response.config.url}</p><p>内容：网络请求不存在</p>`,
                        duration: 5000,
                        dangerouslyUseHTMLString: true
                    });
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    ElNotification.error({
                        message: error.response.statusText ? `发生错误：<p>地址：${error.response.config.url}</p><p>内容：${error.response.statusText}</p>` : '未知异常，请确认输入是否异常，仍无法解决请与技术支持联系。',
                        duration: 5000,
                        dangerouslyUseHTMLString: true
                    });
            }
            return Promise.reject(error.response);
        } else {
            console.log(error);
            ElNotification.error({
                message: `发生错误：<p>地址：${error.config.url}</p><p>内容：${JSON.stringify(error.message)}</p>`,
                duration: 5000,
                dangerouslyUseHTMLString: true
            });
        }
        return Promise.reject(error);
    }
);

const request = (data) => {

    return instance(data);
}


export default request;