import fetch from '../util/request';
// console.log(fetch);
export default {
    login(data) {
        return fetch({
            url: '/user/login',
            method: 'post',
            data
        });
    },
    getCode(params) {
        return fetch({
            url: '/user/login/getcode',
            method: 'get',
            params
        });
    },
    createCaptcha(params){
        return fetch({
            url: '/user/createCaptcha',
            method: 'get',
            params
        });
    },
    getMyInfo(params) {
        return fetch({
            url: '/user/me',
            method: 'get',
            params
        });
    },
    logout(params) {
        return fetch({
            url: '/user/logout',
            method: 'post',
            params
        });
    },
    reg(data) {
        return fetch({
            url: '/user/reg',
            method: 'post',
            data
        });
    },
    pages(params) {
        return fetch({
            url: '/user/pages',
            method: 'get',
            params
        });
    },
    updatePswd(data) {
        return fetch({
            url: '/user/updatePswd',
            method: 'post',
            data
        });
    },
};
